<template>
  <div :class="{ loading: loading }" class="contact-us">
    <div class="w-full md:w-3/4 m-auto mt-12">
      <p class="p-light">
        <a class="link" target="_blank" href="http://www.smsenergetics.com/"
          >Safety Management Services, Inc.</a
        >
        sponsors the ET Users Group and the Test Methods Matrix™ to facilitate
        improving and standardizing in-process and transportation test methods.
      </p>

      <p class="p-light mt-4">
        The ET Users Group can be contacted by filling out the below form or by
        phone at 801-567-0456.
      </p>
    </div>

    <div class="w-full md:w-3/4 m-auto mt-8">
      <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="name">
            Name (required)
          </label>
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name="name"
            id="name"
            type="text"
            v-model="name"
            placeholder=""
            required
          />
        </div>

        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="name">
            Email (required)
          </label>
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name="email"
            id="email"
            type="email"
            v-model="email"
            placeholder=""
            required
          />
        </div>

        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="name">
            Subject
          </label>
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name="subject"
            id="subject"
            type="text"
            v-model="subject"
            placeholder=""
          />
        </div>

        <div class="mb-4">
          <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="message"
          >
            Message
          </label>
          <textarea
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name="message"
            id="message"
            type="date"
            v-model="message"
            placeholder=""
          >
          </textarea>
        </div>

        <div class="flex items-center justify-between">
          <button
            id="submit"
            @click="sendMail"
            style="background-color:#f09a3e;"
            class="text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            Send
          </button>
        </div>
        <div class="mb-4"></div>
      </div>
    </div>
    <div class="modal">
      <img src="@/assets/ajax-loader.gif" alt="pending" />
      <!-- Place at bottom of page -->
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactUs",
  data: function() {
    return {
      loading: false,
      name: "",
      email: "",
      subject: "",
      message: ""
    };
  },
  mounted() {},
  methods: {
    sendMail() {
      let messageData = {
        name: this.name,
        email: this.email,
        subject: this.subject,
        message: this.message
      };

      if (!messageData.name) {
        alert("Name is required");
        return false;
      }
      if (!messageData.email) {
        alert("Email is required");
        return false;
      }
      if (!messageData.message) {
        alert("No message to send.");
        return false;
      }

      this.loading = true;
      fetch("/sendMail", {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "no-cors", // no-cors, *cors, same-origin
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(messageData) // body data type must match "Content-Type" header
      }).then(() => {
        this.loading = false;
        alert("Message Sent");
        this.subject = "";
        this.message = "";
      });
    }
  }
};
</script>

<style lang="scss">
.contact-us {
  #submit:hover {
    background-color: #454d5f !important;
  }

  .modal {
    display: none;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 0.8) 50% 50% no-repeat;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 32px;
      height: 32px;
      margin-top: -16px; /* Half the height */
      margin-left: -16px; /* Half the width */
      opacity: 0.8;
    }
  }
  &.loading {
    overflow: hidden;
  }
  &.loading .modal {
    display: block;
  }
}
</style>
